'use client';
import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import ResourceCard from '@/shared/components/ResourceCard';
import {
  DotButton,
  useDotButton,
} from '@/shared/components/Carousel/CarouselDotButton';
import '@/shared/components/Carousel/carousel.css';

import ResourceInterface from '@/shared/apis/resources/types.resources.d';
import { Box } from '@mui/material';

interface CarouselProps {
  cards: ResourceInterface[];
}

export default function Carousel({ cards }: CarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  });

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <Box className="embla">
      <Box className="embla__viewport" ref={emblaRef}>
        <Box className="embla__container">
          {cards.map((item, index) => (
            <Box key={`slide_${index}`} className="embla__slide">
              <ResourceCard resource={item} disbleRowResponsive />
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={'embla__dot'.concat(
              index === selectedIndex ? ' embla__dot--selected' : '',
            )}
          />
        ))}
      </Box>
    </Box>
  );
}
