'use client';
import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ResourceCard from '../ResourceCard';
import Button from '../ButtonComponent';
import Icon from '../Icon';
import theme from '@/lib/theme/theme';
import { colors } from '@/lib/theme/palette';
import ResourceInterface from '@/shared/apis/resources/types.resources.d';
import DesktopRenderComponent from '@/shared/components/DesktopRenderComponent';
import MobileRenderComponent from '@/shared/components/MobileRenderComponent';
import KeywordsFilter from '@/shared/components/KeywordsFilter';
import ResourceDataApi from '@/shared/apis/resources/resources.api';
import KeywordValuesList from '@/shared/components/KeywordValuesList';
import { removeItemAll } from '@/lib/utils';
// import CompanyDataApi from '@/app/company/[id]/api/company.api';
import ResourcesDataApi from '@/shared/apis/resources/resources.api';
import MobileFilter from '@/shared/components/MobileFilter';
import ResourceTypeComboFilter from '@/shared/components/ResourceTypeComboFilter';
import { TResourceType } from '@/shared/apis/resourceType/types.d';
import Pagination from '@/shared/components/Pagination';

type FilterValues = {
  [key: string]: string[];
};

type Props = {
  sx?: React.CSSProperties;
  resources: ResourceInterface[];
  title: string;
  companyId?: string;
  isShowItemsCount?: boolean;
  totalCount?: number;
  selectedTags?: string[];
  tags?: string[];
  onSelectedTags?: (tags: object[]) => void;
  onRemoveTag?: (tag: string) => void;
  onRemoveAllTags?: () => void;
  onSearch?: (event: React.FormEvent<HTMLInputElement>) => void;
  resourceTypes: TResourceType[];
  hasPagination?: boolean;
  hasCatalogLink?: boolean;
};

const ResourcesList = (props: Props) => {
  const {
    resources,
    title,
    isShowItemsCount,
    companyId,
    resourceTypes,
    totalCount = 0,
    hasPagination,
    hasCatalogLink,
    sx,
  } = props;

  const { push } = useRouter();
  const isSmallScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down('sm'),
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [filteredResources, setFilteredResources] = useState<
    ResourceInterface[]
  >(resources || []);
  const [filteredTotal, setFilteredTotal] = useState<number>(totalCount || 0);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    keywords: [],
    resourceType: [],
  });
  const [page, setPage] = useState<number>(1);

  const goToCatalog = () => {
    const params = new URLSearchParams();
    params.set('type', 'resources');
    if (filterValues.keywords.length) {
      params.set('keywords', filterValues.keywords.join(','));
    }
    if (filterValues.resourceType.length) {
      params.set('resourceType', filterValues.resourceType.join(','));
    }
    push(`library?${params.toString()}`);
  };

  const changePage = async (newPage: number) => {
    setPage(newPage);
    await loadResources({ ...filterValues }, newPage);
  };

  const changeKeywordsFilterCallback = async (
    filter: string,
    value: string,
  ) => {
    let newValues: string[] = [];
    const keywordsValues = filterValues.keywords;
    if (keywordsValues.includes(value)) {
      newValues = removeItemAll([...keywordsValues], value);
    } else {
      newValues = [...keywordsValues];
      newValues.push(value);
    }
    const newFilterValues = {
      ...filterValues,
      keywords: newValues,
    };
    setFilterValues(newFilterValues);
    setPage(1);
    await loadResources(newFilterValues, 1);
  };

  const changeTypeFilterCallback = async (filter: string, value: string) => {
    const newFilterValues = {
      ...filterValues,
      resourceType: value ? value.split(',') : [],
    };
    setPage(1);
    setFilterValues(newFilterValues);
    await loadResources(newFilterValues, 1);
  };

  const fetchTrendingResources = async (filters: FilterValues) => {
    const { data } = await ResourceDataApi.FetchResources({
      isTrend: true,
      ...(filters.keywords.length && {
        keywords: filters.keywords.join(','),
      }),
      ...(filters.resourceType.length && {
        resourceType: filters.resourceType.join(','),
      }),
    });
    return data;
  };

  const fetchGeneralResources = async (filters: FilterValues) => {
    const { data } = await ResourceDataApi.FetchResources({
      ...(filters.keywords.length && {
        keywords: filters.keywords.join(','),
      }),
      ...(filters.resourceType.length && {
        resourceType: filters.resourceType.join(','),
      }),
    });
    return data;
  };

  const loadResources = async (filters: FilterValues, page: number) => {
    setLoading(true);

    if (companyId) {
      const { data, total_items } = await ResourcesDataApi.FetchResources({
        companyId,
        pageCount: '12',
        page,
        ...(filters.keywords.length && {
          keywords: filters.keywords.join(','),
        }),
        ...(filters.resourceType.length && {
          resourceType: filters.resourceType.join(','),
        }),
      });
      setFilteredResources(data);
      setFilteredTotal(total_items);
    } else {
      const [trendingResources, generalResources] = await Promise.all([
        fetchTrendingResources(filters),
        fetchGeneralResources(filters),
      ]);
      const allResources = [...trendingResources, ...generalResources];
      const allUniqueResources = [
        ...new Map(allResources.map(item => [item['id'], item])).values(),
      ];
      setFilteredResources(allUniqueResources.splice(0, 12));
    }

    setLoading(false);
  };

  const getResourcesElements = (resourceItems: ResourceInterface[]) => {
    return resourceItems.map((resourse: ResourceInterface) => {
      return (
        <ResourceCard
          key={resourse.id}
          resource={resourse}
          loading={loading}
          sx={{ maxWidth: 'auto' }}
        />
      );
    });
  };

  const clearAllKeywords = async () => {
    setFilterValues({
      ...filterValues,
      keywords: [],
    });
    await loadResources({ ...filterValues, keywords: [] }, 1);
  };

  const removeKeyword = async (name: string) => {
    const newKeywords = removeItemAll([...filterValues.keywords], name);
    const newFilterValues = {
      ...filterValues,
      keywords: newKeywords,
    };
    setFilterValues(newFilterValues);
    await loadResources(newFilterValues, 1);
  };

  const applyFiltersCallback = async (mobilefilters: FilterValues) => {
    setFilterValues(mobilefilters);
    await loadResources(mobilefilters, 1);
  };

  return (
    <Box sx={{ ...sx }}>
      <Box
        display="flex"
        alignItems="center"
        marginBottom={{ xs: 3.5, sm: 4.5 }}
      >
        <Box display="flex" alignItems="baseline" flex="1 0 45%">
          <Typography component="h2" variant="h2">
            {title}
          </Typography>

          {isShowItemsCount && !isSmallScreen && (
            <Typography
              component="span"
              variant={isSmallScreen ? 'caption2' : 'caption6'}
              marginLeft={{ xs: 1, sm: 2 }}
              flex="1 0 auto"
              sx={{ fontWeight: 400 }}
            >
              {filteredTotal || 0} {filteredTotal === 1 ? 'item' : 'items'}
            </Typography>
          )}
        </Box>

        <Box
          display="flex"
          flex="1 0 auto"
          justifyContent="flex-end"
          marginLeft={2}
        >
          <DesktopRenderComponent>
            <Box sx={{ width: '100%', maxWidth: '280px' }}>
              <KeywordsFilter
                callback={changeKeywordsFilterCallback}
                placeholder="Filter by keyword"
              />
            </Box>
            <ResourceTypeComboFilter
              types={resourceTypes}
              callback={changeTypeFilterCallback}
            />
          </DesktopRenderComponent>
          <MobileRenderComponent>
            <MobileFilter
              searchType="resources"
              defaultFilterValues={filterValues}
              applyCallback={applyFiltersCallback}
              hideSearch
            />
          </MobileRenderComponent>
        </Box>
      </Box>

      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        marginBottom={4}
        columnGap={1.25}
        rowGap={1.25}
      >
        {filterValues.keywords.length ? (
          <DesktopRenderComponent>
            <KeywordValuesList
              keywords={filterValues.keywords}
              onClearAll={clearAllKeywords}
              onRemoveKeyword={removeKeyword}
              sx={{
                width: '100%',
              }}
            />
          </DesktopRenderComponent>
        ) : (
          ''
        )}
      </Box>

      {filteredResources.length ? (
        <Box
          component="div"
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: 'repeat(auto-fill, minmax(340px, 1fr))',
          }}
          columnGap={{ xs: 2, sm: 2.5 }}
          rowGap={{ xs: 2, sm: 2.5 }}
        >
          {getResourcesElements(filteredResources)}
        </Box>
      ) : (
        <Typography
          component="div"
          variant="h3"
          sx={{
            color: colors.black,
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          No results were found. Please try another search
        </Typography>
      )}
      {hasPagination && filteredResources.length ? (
        <Pagination
          page={page}
          totalCount={filteredTotal}
          callback={changePage}
        />
      ) : (
        ''
      )}
      {hasCatalogLink ? (
        <Button
          variant="outlined-primary"
          width={{
            xs: 165,
            sm: 208,
            xl: 255,
          }}
          sx={{ margin: '4rem auto 0' }}
          append={
            <Icon
              icon="arrow-right"
              width={{ xs: 14, sm: 20 }}
              height={{ xs: 14, sm: 20 }}
              color={colors.blue}
            />
          }
          onClick={goToCatalog}
        >
          Show all resources
        </Button>
      ) : (
        ''
      )}
    </Box>
  );
};

export default ResourcesList;
