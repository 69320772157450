'use client';
import React, { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { colors } from '@/lib/theme/palette';
import { TResourceType } from '@/shared/apis/resourceType/types.d';
import { FormControl, SxProps } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      transform: 'translateX(-70px)',
    },
  },
};

type ComboFilterProps = {
  types: TResourceType[];
  callback?: (filter: string, value: string) => void;
  sx?: SxProps;
  selectSx?: SxProps;
};

export default function ResourceTypeComboFilter({
  types,
  callback,
  selectSx,
  sx,
}: ComboFilterProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === 'string' ? value.split(',') : value);
    if (callback) {
      callback(
        'resourceType',
        typeof value === 'string' ? value : value.join(','),
      );
    }
  };

  return (
    <FormControl
      sx={{
        display: 'block',
        ...sx,
      }}
    >
      <Select
        multiple
        displayEmpty
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label="Keyword" />}
        renderValue={selected => {
          return (
            <Typography variant="subtitle1">
              Type
              {selected.length > 0 ? ` (${selected.length})` : ''}
            </Typography>
          );
        }}
        MenuProps={MenuProps}
        sx={{
          height: '48px',
          backgroundColor: colors.white,
          width: '100%',
          maxWidth: '180px',
          borderRadius: 2,
          marginLeft: 1.5,
          '& fieldset': {
            top: 0,
            '& legend': {
              display: 'none',
            },
          },

          ...selectSx,
        }}
      >
        {types.map(tag => (
          <MenuItem key={tag.value} value={tag.value}>
            <Checkbox checked={selectedValues.includes(tag.value)} />
            <ListItemText
              primary={`${tag.name} ${tag.resources_count ? `(${tag.resources_count})` : ''}`}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
